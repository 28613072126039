<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('Form.Section.SubmitOfferNoOfferResponse')"
        :cards="items"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          v-bind="card"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        >
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  data() {
    return {
      items: [],

      statuses: [
        {
          key: "New",
          label: this.getRes("CustMenu.Com.Action.SubmitOffer"),
          color: "green",
        },
        {
          key: "NoOffer",
          label: this.getRes("CustMenu.Com.Action.SubmitNoOffer"),
          color: "orange",
        },
        {
          key: "NoResponse",
          label: this.getRes("System.OfferType.NoResponse"),
          color: "red",
        },
        // {
        //   key: "LateApproved",
        //   label: this.getRes("System.OfferType.LateApproved"),
        //   color: "teal",
        // },
        // {
        //   key: "LateReject",
        //   label: this.getRes("System.OfferType.LateReject"),
        //   color: "brown",
        // },
        {
          key: "Failed",
          label: this.getRes("System.OfferType.Failed"),
          color: "pink",
        },
        {
          key: "Invalid",
          label: this.getRes("System.OfferType.Invalid"),
          color: "purple",
        },
      ],
    };
  },

  methods: {
    async getTenderers() {
      return (
        await this.$proSmart.tenderView.getOpeningRecordTenderResponses(
          this,
          this.$route.params.id
        )
      )
        .sort((left, right) => left.ref.localeCompare(right.ref))
        .map(({ ref, name, responseType, processId }) => {
          let actions;
          if (responseType !== "Failed" && processId) {
            actions = [{ processId: processId, viewForm: true }];
          }

          return {
            alias: ref,
            title: name,
            status: responseType,
            actions,
          };
        });
    },
  },

  async created() {
    this.items = await this.getTenderers();
  },
};
</script>
